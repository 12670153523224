import * as moment from 'moment';
import React from 'react';

interface BlogPostHeroProps {
  title: string;
  imageUrl: string;
  date: string;
  author?: {
    name: string;
    title: string;
    profilePhotoUrl: string;
  };
  background?: string;
  foreground?: string;
}

const BlogPostHero: React.FC<BlogPostHeroProps> = ({
  title,
  imageUrl,
  date,
  author,
  background,
  foreground,
}) => {
  return (
    <>
      <div
        className={`${
          background || 'bg-violet-alt'
        } bg-opacity-15 pt-32 md:pt-48 ${author ? 'pb-64' : 'pb-48'} lg:pb-80`}
      >
        <div className="u-container px-6 lg:px-md">
          <div
            className={`mb-6 ${
              foreground || 'text-violet-alt'
            } font-bold tracking-tighter leading-tight`}
          >
            {moment(date).format('MMM DD, YYYY')}
          </div>
          <h1 className="mb-12 u-h1 w-full max-w-4xl">{title}</h1>
        </div>
      </div>
      <div
        className={`u-container flex flex-col-reverse lg:flex-row lg:items-end ${
          author ? '-mt-64' : '-mt-48'
        } lg:-mt-80 px-6 lg:px-md`}
      >
        <div className="w-full lg:w-8/12">
          {imageUrl && (
            <img src={imageUrl} alt={title} className="w-full h-auto" />
          )}
        </div>
        {author && (
          <div className="mb-8 lg:mb-24 xl:mb-36 lg:pl-11">
            <div className="border-t border-violet-alt pt-6 mb-5">
              <img
                src={author.profilePhotoUrl}
                alt={author.name}
                className="w-12 h-auto rounded-full bg-violet-alt border-3 border-white"
              />
            </div>
            <div className="text-lg font-semibold tracking-tighter leading-snug">
              {author.name}
            </div>
            <div className="text-15px tracking-tighter leading-snug">
              {author.title}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BlogPostHero;
